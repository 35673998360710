import { NAME as HeaderName } from '@spa-ec/components/Header/Header.theme';
import { NAME as ConsumerListName, } from '@spa-ec/components/PersonalizedCockpit/ConsumerList/ConsumerList.theme';
import { NAME as ContentPageName } from '@spa-ec/routes/ContentPage/ContentPage.theme';
import { NAME as CartSummaryName } from '@spa-ec/components/QliroCheckout/CartSummary.theme';
import { NAME as QliroCheckoutName } from '@spa-ec/components/QliroCheckout/QliroCheckout.theme';
import { NAME as ProductDetailsName } from '@spa-ec/components/ProductDetails/ProductDetails.theme';
import { NAME as ProductStockStatusMonitorName, } from '@spa-ec/components/ProductDetails/ProductStockStatusMonitor/ProductStockStatusMonitor.theme';
import { NAME as PageTitleName } from '@spa-ec/components/StaticPageComponents/PageTitle.theme';
import { NAME as CartTableName } from '@spa-ec/displayComponents/CartTable/CartTable.theme';
import { NAME as SummaryTableName } from '@spa-ec/displayComponents/CartTable/SummaryTable/SummaryTable.theme';
import { NAME as ProductTableName } from '@spa-ec/components/ProductTable/ProductTable.theme';
import { NAME as ProductTableCategoriesName, } from '@spa-ec/components/ProductTable/ProductTableCategory/ProductTableCategory.theme';
import { ProductTableColumnId } from '@spa-ec/components/ProductTable/constants';
import { NAME as ProductRowName } from '@spa-ec/components/ProductTable/Rows/ProductRow.theme';
import { ProductShelfCategoryKey } from '@spa-core/store/products/constants';
import { NAME as FooterName } from '@spa-ec/components/Footer/Footer.theme';
import { NAME as PasswordRequestName } from '@spa-ec/components/PasswordRequest/PasswordRequest.theme';
import { NAME as SiteLogoName } from '@spa-ec/components/Header/HeaderSiteLogo/SiteLogo.theme';
import { NAME as PrisjaktCheapestName } from '@spa-ec/components/PrisjaktCheapest/PrisjaktCheapest.theme';
import { NAME as StickerName } from '@spa-ec/components/Sticker/Sticker.theme';
import { NAME as MobileDropDownName, } from '@spa-ec/components/Header/HeaderMobileDropDown/MobileDropDown.theme';
import theme from './theme.conf';
const mobileDropDownTheme = {
    showCustomerTypeSelector: false,
};
const headerTheme = {
    showCountrySelector: false,
    showCustomerTypeSelector: false,
};
const consumerListTheme = {
    alternateDustbag: false,
};
const contentPageTheme = {
    showPersonalizedCockpit: true,
    showSideNavigationOnStaticPages: true,
    showTitleOnContentPageDesktop: true,
    showTitleOnContentPageMobile: true,
    sideContentPadding: 'px-4',
};
const cartSummaryTheme = {
    headerFontSize: 'm',
    summaryFontSize: 'm',
    summaryFontClass: 'text-m',
    borderInSummary: true,
};
const qliroCheckoutTheme = {
    primaryColor: theme.col.primary.fg,
    callToActionColor: theme.col.primary.fg,
    callToActionHoverColor: theme.col.primary.peer,
};
const productDetailsTheme = {
    gapSeparator: true,
    applyPaddingSubscriptionPanel: false,
};
const productStockStatusMonitor = {
    conditionPageEndpoint: '/Kopvillkor',
};
const pageTitleTheme = {
    noBorder: true,
    fg: theme.col.tertiary.fg,
    bg: 'white',
    showLogoutIcon: false,
    combineSections: true,
    bottomBorderColor: '#343434',
};
const cartTableTheme = {
    showProductDetails: true,
    showPerPiecePriceTitle: true,
    showPerKronaInCartTable: false,
};
const summaryTableTheme = {
    showPerPiecePriceTitle: true,
};
const productTableTheme = {
    categoriesOrder: [
        ProductShelfCategoryKey.ONE_BUY_PRODUCTS,
        ProductShelfCategoryKey.BUDGET,
        ProductShelfCategoryKey.RECOMMENDED,
        ProductShelfCategoryKey.ORIGINAL,
        ProductShelfCategoryKey.OPTIMUM,
        ProductShelfCategoryKey.UPSELLING,
        ProductShelfCategoryKey.FREE_CATEGORIES_LIST,
    ],
    checkCategoriesForProducts: [
        ProductShelfCategoryKey.RECOMMENDED,
        ProductShelfCategoryKey.OPTIMUM,
        ProductShelfCategoryKey.BUDGET,
        ProductShelfCategoryKey.ORIGINAL,
    ],
    hideShelfTitleOnMobile: false,
};
const productTableCategoriesTheme = {
    tableColumns: [
        {
            id: ProductTableColumnId.PRODUCT_IMAGE,
        },
        {
            id: ProductTableColumnId.PRODUCT_CODE,
            title: 'product.code.short',
            classes: 'productCodeTableData',
        },
        {
            id: ProductTableColumnId.PRODUCT_CORRESPONDS_TO,
            title: 'product.code.replaces',
            classes: 'productCorrespondsToTableData',
        },
        {
            id: ProductTableColumnId.PRODUCT_MANUFACTURER,
            title: 'product.manufacturer',
            classes: 'productManufacturerTableData',
        },
        {
            id: ProductTableColumnId.PRODUCT_NAME,
            title: 'word.product',
            classes: 'lg:w-1/4',
        },
        {
            id: ProductTableColumnId.STOCK,
            title: 'product.variants.in.stock',
            classes: 'stockTableData',
        },
        {
            id: ProductTableColumnId.PRICE,
            title: 'product.tablerow.price.title',
            classes: 'md:w-1/5 lg:w-auto md:px-1 priceTableData text-center',
        },
        {
            id: ProductTableColumnId.ADD_TO_CART_AND_SUBSCRIPTION,
            classes: 'text-right',
        },
    ],
};
const productRowTheme = {
    showOneKronaSticker: true,
    productCodeColor: theme.col.pale.alt,
};
const footerTheme = {
    showNewsLetter: false,
};
const passwordRequestTheme = {
    enablePasswordRequestByCustomerNumber: true,
};
const prisjaktCheapestTheme = {
    storeIds: { 'dammsugarpasar-se': 34614 },
};
const siteLogoTheme = {
    siteLogo: {
        imagesRootPath: '/images/sitelogo',
        paths: {
            b2c: {
                default: '/logo.svg',
            },
            b2b: {
                default: '/logo.svg',
            },
        },
    },
};
const stickerTheme = {
    showOneKronaSticker: true,
    stickers: {
        se: '1krSticker.png',
        default: '1krSticker.png',
    },
};
export default {
    [HeaderName]: headerTheme,
    [ConsumerListName]: consumerListTheme,
    [ContentPageName]: contentPageTheme,
    [CartSummaryName]: cartSummaryTheme,
    [QliroCheckoutName]: qliroCheckoutTheme,
    [ProductDetailsName]: productDetailsTheme,
    [ProductStockStatusMonitorName]: productStockStatusMonitor,
    [PageTitleName]: pageTitleTheme,
    [CartTableName]: cartTableTheme,
    [SummaryTableName]: summaryTableTheme,
    [ProductTableName]: productTableTheme,
    [ProductTableCategoriesName]: productTableCategoriesTheme,
    [ProductRowName]: productRowTheme,
    [FooterName]: footerTheme,
    [PasswordRequestName]: passwordRequestTheme,
    [PrisjaktCheapestName]: prisjaktCheapestTheme,
    [SiteLogoName]: siteLogoTheme,
    [StickerName]: stickerTheme,
    [MobileDropDownName]: mobileDropDownTheme,
};
